import { ExtendedContentType } from './utils/types'

const Content: ExtendedContentType = {
  'enroll-profile': {
    heading: 'Create your VIP Account',
    subHeading:
      'Unlock huge savings, loyalty perks, and more when you become a VIP.',
    submitButton: 'Create Account',
    divider: 'or sign up with',
    cta: 'Create Account'
  },
  identify: {
    heading: 'Sign in to your VIP Account',
    cta: 'Sign In',
    divider: 'or sign in with'
  },
  'select-authenticator-unlock-account': {
    heading: 'Unlock your account',
    subHeading: 'Enter your email below to unlock it.',
    cta: 'Next'
  },
  'challenge-authenticator': {
    heading: 'Check your email',
    subHeading: 'Click the link in your email or enter the code below',
    cta: 'Next',
    divider: 'or'
  },
  'challenge-authenticator-email': {
    heading: 'Check your email',
    subHeading: 'Click the link in your email or enter the code below',
    cta: 'Next',
    divider: 'or'
  },
  'challenge-authenticator-phone': {
    heading: 'We texted you a code',
    subHeading:
      'We sent a code to the number you provided. Please enter the code below.',
    cta: 'Next',
    divider: 'or'
  },
  'challenge-authenticator-password': {
    heading: 'Enter your password',
    cta: 'Sign In',
    divider: 'or'
  },
  'identify-recovery': {
    heading: 'Update your password',
    subHeading: 'To update your password, enter your email address.',
    cta: 'Next'
  },
  'authenticator-verification-data': {
    heading: 'Get a link sent to your email',
    subHeading: 'Click the button to get a secure link sent to your email',
    cta: 'Email me a link'
  },
  'reset-authenticator': {
    heading: 'Update your password',
    subHeading: `Enter your new password and we will sign you back in.`,
    cta: 'Update'
  },
  'select-authenticator-enroll': {
    heading: `Congrats! You're a VIP.`,
    subHeading: 'Verify your account below for added security.',
    cta: 'Next',
    divider: 'or'
  },
  'select-authenticator-authenticate': {
    heading: 'How do you want to sign in?',
    cta: 'Next'
  },
  'enroll-authenticator': {
    heading: `You're almost done!`,
    subHeading:
      'Click the link in the email we sent you or enter the code below to verify your email and complete registration.',
    cta: 'Submit Code',
    divider: 'or'
  },
  'enroll-authenticator-phone': {
    heading: 'We texted you a code',
    subHeading:
      'We sent a code to the number you provided. Please enter the code below.',
    cta: 'Next'
  },
  'authenticator-enrollment-data': {
    heading: 'Enter your phone number',
    subHeading: 'Enter your phone number to finish verifying your account.',
    cta: 'Text Me a Code'
  },
  'select-authenticator-enroll-password': {
    heading: 'Create your password',
    cta: 'Next'
  },
  'select-authenticator-enroll-phone': {
    heading: 'Verify with you phone',
    cta: 'Next'
  }
} as const

export default Content
