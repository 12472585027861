import OktaAuth, { NextStep } from '@okta/okta-auth-js'
import isAbortSignalTimeoutSupported from '@/shared-utils/is-abortSignal-supported'
import CONFIG from 'isomorphic-config'

const EMAILS_BASE_URI = `https://${CONFIG.client.okta.domain}/idp/myaccount/emails`

type EmailResponseBody = {
  id: string
  status: string
}

type UserStatusResponse = {
  ok: boolean
  body: ReadonlyArray<EmailResponseBody> | unknown
}

export async function checkUserStatus(
  accessToken: string
): Promise<UserStatusResponse> {
  // check if user email is verified
  const options = {
    method: 'GET',
    signal: isAbortSignalTimeoutSupported()
      ? AbortSignal.timeout(3000)
      : undefined,
    headers: {
      Accept: 'application/json; okta-version=1.0.0',
      Authorization: `Bearer ${accessToken}`
    }
  }
  try {
    const response = await fetch(EMAILS_BASE_URI, options)
    const body = await response.json()
    return {
      ok: response.ok,
      body
    }
  } catch (err: unknown) {
    return {
      ok: false,
      body: err
    }
  }
}

export async function verifyEmail(
  inputOTP: string,
  challengeID: string,
  emailID: string,
  accessToken: string
) {
  const URI = `${EMAILS_BASE_URI}/${emailID}/challenge/${challengeID}/verify`
  const options = {
    method: 'POST',
    signal: isAbortSignalTimeoutSupported()
      ? AbortSignal.timeout(3000)
      : undefined,
    headers: {
      Accept: 'application/json; okta-version=1.0.0',
      Authorization: `Bearer ${accessToken}`,
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      verificationCode: inputOTP
    })
  }
  try {
    const response = await fetch(URI, options)
    return response.ok
      ? {
          ok: response.ok
        }
      : {
          ok: response.ok,
          errorReason: 'Please verify code and try again'
        }
  } catch (err: unknown) {
    return {
      body: err
    }
  }
}

export async function challengeEmail(
  emailID: string,
  accessToken: string,
  errHandler: () => void
) {
  const URI = `${EMAILS_BASE_URI}/${emailID}/challenge`
  const options = {
    method: 'POST',
    signal: isAbortSignalTimeoutSupported()
      ? AbortSignal.timeout(3000)
      : undefined,
    headers: {
      Accept: 'application/json; okta-version=1.0.0',
      Authorization: `Bearer ${accessToken}`
    }
  }
  try {
    const response = await fetch(URI, options)
    const body = await response.json()
    return {
      ok: response.ok,
      body
    }
  } catch (err: unknown) {
    errHandler()
    return {
      ok: false,
      body: err
    }
  }
}

export async function refreshToken(oktaClient: OktaAuth) {
  await oktaClient.revokeRefreshToken()
  oktaClient.tokenManager.clear()
}
export function isEmailVerifried(nextStep: NextStep) {
  return nextStep?.authenticatorEnrollments?.find(
    authenticatorEnrollment => authenticatorEnrollment.type === 'email'
  )
}
