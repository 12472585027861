/* istanbul ignore file */
import React, { useEffect, useState } from 'react'
import { useOktaAuth } from '@okta/okta-react'
import {
  hasErrorInUrl,
  hasInteractionCode,
  hasAuthorizationCode,
  IdxStatus,
  IdxTransaction,
  EmailVerifyCallbackResponse
} from '@okta/okta-auth-js'
import analytics from '@/shared-utils/analytics'
import capitalizeLetter from '@/shared-utils/capitalize-letter'
import LoadIndicator from './LoadIndicator'
import { RootTypes } from './utils/types'
import OTPPage from './OTPPage'

type LoginCallbackTypes = Pick<RootTypes, 'setTransaction' | 'transaction'>

function LoginCallback({ setTransaction, transaction }: LoginCallbackTypes) {
  const { oktaAuth } = useOktaAuth()
  const [otp, setOtp] = useState('')

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const loginCallbackErrorMessage = urlParams.get('error_description')
    const loginCallbackErrorKey = urlParams.get('error')
    if (loginCallbackErrorMessage) {
      const errorDescription = loginCallbackErrorMessage

      analytics.log({
        message: `Standalone - LoginCallback - Error Description in Query Params- ${errorDescription}`
      })

      const formatErrorDescription = capitalizeLetter(errorDescription)

      setTransaction({
        status: IdxStatus.TERMINAL,
        messages: [
          {
            message: formatErrorDescription,
            class: 'ERROR',
            i18n: {
              key: loginCallbackErrorKey
            }
          }
        ]
      } as IdxTransaction)
    }
  }, [setTransaction])

  useEffect(() => {
    const loadTransaction = async () => {
      try {
        const existingTransaction = await oktaAuth.idx.proceed()
        setTransaction(existingTransaction)
      } catch (error) {
        const catchErrorMessage =
          error instanceof Error
            ? error.message
            : error?.toString() ?? 'Unknown error'
        analytics.logError({
          message: `Standalone - LoginCallback - Could not load Transaction: ${catchErrorMessage}`
        })
        setTransaction({
          status: IdxStatus.FAILURE
        } as IdxTransaction)
      }
    }

    if (!transaction && oktaAuth.idx.canProceed()) {
      void loadTransaction()
    }
  })

  useEffect(() => {
    const parseFromUrl = async () => {
      try {
        if (hasInteractionCode(window.location.search)) {
          return
        }

        if (hasAuthorizationCode(window.location.search)) {
          await oktaAuth.handleLoginRedirect()
        } else {
          analytics.logError({
            message: `Standalone - LoginCallback - Unable to parse interaction or authorization code`
          })
          setTransaction({
            status: IdxStatus.FAILURE
          } as IdxTransaction)
          throw new Error('Unable to parse url')
        }
      } catch (error) {
        const catchErrorMessage =
          error instanceof Error
            ? error.message
            : error?.toString() ?? 'Unknown error'
        analytics.logError({
          message: `Standalone - LoginCallback - Unable to parse url with error: ${catchErrorMessage}`
        })
        setTransaction({
          status: IdxStatus.FAILURE
        } as IdxTransaction)
      }
    }

    if (hasErrorInUrl(window.location.search)) {
      const url = new URL(window.location.href)
      const error = `${url.searchParams.get('error') as string}: ${
        url.searchParams.get('error_description') as string
      }`
      analytics.logError({
        message: `Standalone - LoginCallback - social sign in returned error: ${error}`
      })
      return
    }
    if (oktaAuth.isLoginRedirect()) {
      void parseFromUrl()
    }

    function isErrorEmailVerifyCallbackResponse(
      error: Error
    ): error is EmailVerifyCallbackResponse {
      const emailVerifyCallbackResponse = error as EmailVerifyCallbackResponse
      if (
        typeof emailVerifyCallbackResponse.otp === 'string' &&
        typeof emailVerifyCallbackResponse.state === 'string'
      ) {
        return true
      }
      return false
    }

    const handleEmailVerifyCallback = async () => {
      try {
        const newTransaction = await oktaAuth.idx.handleEmailVerifyCallback(
          window.location.search
        )
        if (newTransaction?.nextStep?.canSkip) {
          const skipedTransaction = await oktaAuth.idx.proceed({ skip: true })
          setTransaction(skipedTransaction)
          return
        }
        setTransaction(newTransaction as IdxTransaction)
      } catch (error) {
        if (
          error instanceof Error &&
          oktaAuth.idx.isEmailVerifyCallbackError(error) &&
          isErrorEmailVerifyCallbackResponse(error)
        ) {
          setOtp(error.otp)
          return
        }
        analytics.logError({
          message: `Standalone - LoginCallback - social sign - handle email callback returned error`,
          error: JSON.stringify(error)
        })

        setTransaction({
          status: IdxStatus.FAILURE
        } as IdxTransaction)
      }
    }

    if (oktaAuth.idx.isEmailVerifyCallback(window.location.search)) {
      void handleEmailVerifyCallback()
    }
  }, [oktaAuth, setTransaction])

  return !otp ? <LoadIndicator /> : <OTPPage otpCode={otp} />
}

export default LoginCallback
