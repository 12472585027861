import React from 'react'
import { Box, Link, Text, GenericBanner, Layout } from 'pcln-design-system'
import { Attention } from 'pcln-icons'

export default function ErrorPage() {
  return (
    <Layout variation="100" rowGap="lg">
      <GenericBanner
        color="warning.light"
        borderRadius="lg"
        iconLeft={<Attention color="warning.base" />}
        py={2}
        px={3}
        text={<Text>Authentication Error</Text>}
      />
      <Box>
        Some common reasons are:
        <ul>
          <li>You have a Priceline account but it is locked.</li>
          <li>
            Your first or last name have special characters that are not
            supported.
          </li>
        </ul>
        <Box>
          If this problem persists, please{' '}
          <Link href="https://help.priceline.com/" target="_blank">
            visit our help center.
          </Link>
        </Box>
      </Box>
      <Box>
        <Link
          variation="fill"
          size="large"
          href="/home/join?flow=authenticate"
          width="100%"
          mb="12px"
        >
          Back to Sign In Page
        </Link>
        <Link
          variation="fill"
          color="background.base"
          size="large"
          href="/"
          width="100%"
        >
          <Text color="primary">Priceline Home Page</Text>
        </Link>
      </Box>
    </Layout>
  )
}
