import * as yup from 'yup'

const EMAIL_REQUIRED = 'Email is required'
const PASSWORD_REQUIRED = 'Password is required'
const NAME_PATTERN_MESSAGE = 'Names cannot have special characters'
const EMAIL_PATTERN_MESSAGE = 'Email is not in valid format'
const VERIFICATION_CODE_REQUIRED = 'Verification code is required'

const namePattern = /^[a-zA-Z \-.'`]+$/
const emailPattern =
  /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const validationSchema = yup.object({
  firstName: yup
    .string()
    .min(1, 'First name is required')
    .matches(namePattern, NAME_PATTERN_MESSAGE)
    .max(50, 'This field cannot exceed the maximum allowed characters'),
  lastName: yup
    .string()
    .min(1, 'Last name is required')
    .matches(namePattern, NAME_PATTERN_MESSAGE)
    .max(50, 'This field cannot exceed the maximum allowed characters'),
  email: yup
    .string()
    .min(1, EMAIL_REQUIRED)
    .matches(emailPattern, EMAIL_PATTERN_MESSAGE)
    .max(100, 'This field cannot exceed the maximum allowed characters'),
  username: yup
    .string()
    .min(1, EMAIL_REQUIRED)
    .matches(emailPattern, EMAIL_PATTERN_MESSAGE)
    .max(100, 'This field cannot exceed the maximum allowed characters'),
  passcode: yup
    .string()
    .min(1, PASSWORD_REQUIRED)
    .matches(
      /^(?=.*[a-z]).{8,}$/,
      'Password requirements were not met. Password requirements: at least 8 characters, a lowercase letter.'
    ),
  password: yup.string().min(1, PASSWORD_REQUIRED),
  verificationCode: yup.string().min(1, VERIFICATION_CODE_REQUIRED)
})

export default validationSchema
