import React, { useEffect, useState } from 'react'
import { Box, Link, Text, Image, Flex } from 'pcln-design-system'
import { convertErrorMessage } from './utils/util'

function Headline({ headline }: { headline: string }) {
  return (
    <Text textStyle={['heading4', null, null, 'heading2']} mb={2}>
      {headline}
    </Text>
  )
}

function SubHeading({ subHeading }: { subHeading: string }) {
  return (
    <Text textStyle="paragraph" mb={24}>
      {subHeading}
    </Text>
  )
}

export default function TerminalPage() {
  const [message, setMessage] = useState('')
  useEffect(() => {
    const messageFromSession = sessionStorage.getItem('okta_terminal_message')
    sessionStorage.removeItem('okta_terminal_message')
    const convertedMessage = messageFromSession
      ? convertErrorMessage(JSON.parse(messageFromSession)[0])
      : 'Something went wrong.'
    setMessage(convertedMessage)
  }, [])

  return (
    <>
      <Flex justifyContent="space-between">
        <Box width={7 / 8}>
          <Headline headline={message} />
          <SubHeading subHeading="You can go back to sign in and try again." />
        </Box>
        <Box mb={3} ml={3} width={1 / 8}>
          <Image
            src="https://s1.pclncdn.com/design-assets/home/okta/vip.svg"
            width="58"
            height="58"
            alt="VIP logo"
            loading="lazy"
          />
        </Box>
      </Flex>
      <Link
        variation="fill"
        size="large"
        href="/home/join?flow=authenticate"
        width="100%"
        mb="12px"
      >
        Back to Sign In Page
      </Link>
    </>
  )
}
