import React from 'react'
import { Link, Text } from 'pcln-design-system'

export default function CanceledPage() {
  return (
    <>
      <Text textStyle="heading3">Transaction has been canceled!</Text>
      <Link href="/home/join">Back to create account page</Link>
    </>
  )
}
