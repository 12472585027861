/* istanbul ignore file */
import React from 'react'
import { Flex, Spinner } from 'pcln-design-system'

function LoadIndicator() {
  return (
    <Flex justifyContent="space-around">
      <Spinner data-testid="spinner" />
    </Flex>
  )
}
export default LoadIndicator
