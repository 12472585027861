import React from 'react'
import { Link, Text, GenericBanner } from 'pcln-design-system'
import { Information } from 'pcln-icons'

export default function OTPPage({ otpCode }: { otpCode: string }) {
  return (
    <>
      <GenericBanner
        color="notify.light"
        borderRadius="lg"
        iconLeft={<Information color="success.Dark" />}
        py={2}
        px={3}
        mb={4}
        text={
          <Text>
            It looks like you were attempting to sign in from a different
            browser.
          </Text>
        }
      />
      <Text textStyle="paragraph" textAlign="center" mb={3}>
        Enter this code on the sign-in page from the original browser.
      </Text>
      <Text textStyle="subheading3" textAlign="center" mb={24}>
        {otpCode}
      </Text>
      <Link
        variation="fill"
        size="large"
        href="/home/join?flow=authenticate"
        width="100%"
        mb={4}
      >
        Skip and Go to Sign In
      </Link>
      <Text textStyle="paragraph" textAlign="center">
        If you didn’t request this code, you can ignore this message. Your
        account is safe and can only be accessed with this code.
      </Text>
    </>
  )
}
