// Copied from https://github.com/okta/okta-auth-js/blob/master/samples/generated/react-embedded-auth-with-sdk/src/formTransformer.js
/* istanbul ignore file */
import { type IdxRemediation } from '@okta/okta-auth-js'
import { InputType, NextStepCustom } from './types'

type IdxForm = NonNullable<NonNullable<IdxRemediation['value']>[number]['form']>

const compose =
  (
    ...functions: ((...args: any[]) => any)[] // eslint-disable-line @typescript-eslint/no-explicit-any
  ) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (args: any) =>
    functions.reduceRight((arg, fn) => fn(arg), args)

const inputTransformer = (nextStep: NextStepCustom) => (form: IdxForm) => {
  // only process UI inputs
  const inputs = nextStep.inputs?.filter(
    input => !!input.label || !!input.options
  )

  if (!inputs?.length) {
    return form
  }

  return {
    ...form,
    inputs: inputs.map(
      ({
        label,
        name,
        type,
        secret,
        required,
        options,
        messages
      }: InputType) => {
        let realType = type
        if (secret) {
          realType = 'password'
        } else if (type === 'string') {
          realType = 'text'
        } else if (type === 'boolean') {
          realType = 'checkbox'
        }
        return { label, name, type: realType, required, options, messages }
      }
    )
  }
}

const securityQuestionTransformer =
  (nextStep: NextStepCustom) => (form: IdxForm) => {
    const contextualData = nextStep.authenticator?.contextualData
    const questionKeys = contextualData?.questionKeys
    const questions = contextualData?.questions
    const enrolledQuestion = contextualData?.enrolledQuestion

    if (enrolledQuestion) {
      // verification form
      return {
        ...form,
        text: { value: enrolledQuestion.question }
      }
    }
    if (questions && questionKeys) {
      // enrollment form
      return {
        ...form,
        select: {
          label: 'Choose a security question',
          name: 'questionKey',
          options: questions.map(({ questionKey, question }) => ({
            key: questionKey,
            label: question,
            value: question
          }))
        }
      }
    }
    return form
  }

const googleAuthenticatorTransformer =
  (nextStep: NextStepCustom) => (form: IdxForm) => {
    const contextualData = nextStep.authenticator?.contextualData
    const qrcode = contextualData
    const sharedSecret = contextualData

    if (!qrcode && !sharedSecret) {
      return form
    }
    const qrcodeHREF = sharedSecret
    return {
      ...form,
      text: { value: sharedSecret },
      image: { src: qrcodeHREF }
    }
  }

const formTransformer = (nextStep: NextStepCustom) =>
  compose(
    googleAuthenticatorTransformer(nextStep),
    securityQuestionTransformer(nextStep),
    inputTransformer(nextStep)
  )
export default formTransformer
