import React, { useState, useEffect } from 'react'
import { Flex, Text, Button } from 'pcln-design-system'
import { ArrowLeft } from 'pcln-icons'
import analytics from '@/shared-utils/analytics'

const ANALYTICS_CATEGORY_OKTA_STANDALONE = 'Global Register Modal'
const ANALYTICS_ACTION_OKTA_STANDALONE = 'Back Button'

type BackButtonProps = {
  onNavigateBack: (url: string) => void
  redirectUrl: string
}

function BackButton({ onNavigateBack, redirectUrl }: BackButtonProps) {
  const [buttonText, setButtonText] = useState('Back')

  const handleBackClick = () => {
    try {
      const checkoutURL = sessionStorage.getItem('checkoutURL')

      if (checkoutURL) {
        analytics.fireEvent({
          action: ANALYTICS_ACTION_OKTA_STANDALONE,
          category: ANALYTICS_CATEGORY_OKTA_STANDALONE,
          label: 'Checkout'
        })
        onNavigateBack(checkoutURL)
      } else {
        analytics.fireEvent({
          action: ANALYTICS_ACTION_OKTA_STANDALONE,
          category: ANALYTICS_CATEGORY_OKTA_STANDALONE,
          label: 'Not Checkout'
        })
        const { referrer } = document
        if (referrer && referrer !== window.location.href) {
          onNavigateBack(decodeURIComponent(redirectUrl))
        } else {
          onNavigateBack('/')
        }
      }
      sessionStorage.removeItem('checkoutURL')
    } catch (error) {
      const catchErrorMessage =
        error instanceof Error
          ? error.message
          : error?.toString() ?? 'Unknown error'
      analytics.logError({
        message: `Standalone - Back Button failed: ${catchErrorMessage}`
      })
      onNavigateBack('/')
    }
  }

  useEffect(() => {
    if (sessionStorage.getItem('checkoutURL')) {
      setButtonText('Back to Checkout')
      return
    }

    if (redirectUrl?.includes('/cart/checkout')) {
      sessionStorage.setItem('checkoutURL', redirectUrl)
      setButtonText('Back to Checkout')
    }
  }, [redirectUrl])

  return (
    <Button variation="link" onClick={handleBackClick}>
      <Flex alignItems="center" style={{ gap: '8px' }}>
        <ArrowLeft size={20} />
        <Text>{buttonText}</Text>
      </Flex>
    </Button>
  )
}

export default BackButton
