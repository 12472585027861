import React from 'react'
import { GetServerSideProps } from 'next'
import Layout from '@/components/Layout'
import { CustomContext, PageProps } from '@/types'
import CONFIG from 'isomorphic-config'
import Okta from '@/components/Okta/index'
import mapSetiResponse from '@/shared-utils/map-seti-response'
import Meta from '@/components/Meta'
import DEFAULT_META_TAGS from '@/constants/meta'
import setiService from 'server/middleware/seti'
import useCanonicalUrl from '@/hooks/useCanonicalUrl'
import useRedirectUrl from '@/hooks/useRedirectUrl'
import generateBootstrapData from '../../utils/generate-bootstrap-data'
import fetchPCLNHeaderFooterContent from '../../server/utils/pcln-header-footer'
import oktaJwtVerify from '../../server/utils/okta-jwt-verifier'

function Join({ headerHTML, footerHTML, bootstrapData }: PageProps) {
  const { isMobile } = bootstrapData
  const clientScope = isMobile ? 'mobileweb' : 'desktop'

  const issuer = `https://${CONFIG.client.okta.domain}/oauth2/default`
  const callbackUrl = `/home/join/login/callback`

  const oktaClientId = CONFIG.client.okta.clientId

  const canonicalUrl = useCanonicalUrl()
  const redirectUrl = useRedirectUrl()
  return (
    <>
      <Meta
        description={DEFAULT_META_TAGS.description.join}
        pageTitle={DEFAULT_META_TAGS.title.join}
        canonicalUrl={canonicalUrl}
      />
      <Layout headerHTML={headerHTML} footerHTML={footerHTML}>
        <Okta
          redirectUrl={redirectUrl}
          clientId={oktaClientId}
          issuer={issuer}
          callbackUrl={callbackUrl}
          clientScope={clientScope}
        />
      </Layout>
    </>
  )
}

export const getServerSideProps: GetServerSideProps<PageProps> = async ctx => {
  const { req, res } = ctx as unknown as CustomContext
  const bootstrapData = generateBootstrapData('join', req, res)
  const { webstats, referral, appVersion } = bootstrapData
  const { clientGUID: cguid } = webstats

  const [pclnHeaderFooterContent, signInStatus] = await Promise.all([
    fetchPCLNHeaderFooterContent(req, res),
    oktaJwtVerify({ authHdr: req.headers.authorization ?? '', cguid })
  ])

  const setiResponseEvents = await setiService(req, {
    appVersion,
    allowSetiOverrides: true,
    webstats,
    referral,
    reqQueryParams: ctx.query
  })
  return {
    props: {
      headerHTML: pclnHeaderFooterContent.headerHTML,
      footerHTML: '',
      bootstrapData: {
        ...bootstrapData,
        fullSETI: setiResponseEvents,
        seti: mapSetiResponse(setiResponseEvents),
        signInStatus
      }
    }
  }
}

export default Join
