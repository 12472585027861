import React, { useState, useEffect, useMemo, ReactNode } from 'react'
import {
  OktaAuth,
  IdxStatus,
  IdxTransaction,
  isRedirectUri,
  FlowIdentifier
} from '@okta/okta-auth-js'
import { useRouter } from 'next/router'
import {
  Container,
  Box,
  Text,
  Link,
  Flex,
  ButtonChip
} from 'pcln-design-system'
import styled from 'styled-components'
import { Security } from '@okta/okta-react'
import analytics from '@/shared-utils/analytics'
import useSeti from '@/hooks/useSeti'
import pclnExperimentation from '@pcln/experimentation'
import FlowPage from './FlowPage'
import TerminalPage from './TerminalPage'
import VerifyEmail from './VerifyEmail'
import ErrorPage from './ErrorPage'
import CanceledPage from './CanceledPage'
import LoginCallback from './LoginCallback'
import { AuthTabType, InitialTransaction } from './utils/types'
import BackButton from './BackButton'
import { checkUserStatus, isEmailVerifried, refreshToken } from './OktaService'
import { fireLoginEvent, firePageViewEvent } from './ga4'

const Scrim = styled(Box)`
  width: 100%;
  height: 100%;
  background-color: white;
  opacity: 0.5;
  position: absolute;
  z-index: 4;
`

const oktaAuth = ({
  clientId,
  issuer,
  callbackUrl,
  clientScope
}: {
  clientScope: string
  clientId: string
  issuer: string
  callbackUrl: string
}) => {
  return new OktaAuth({
    clientId,
    issuer,
    redirectUri: callbackUrl,
    scopes: [
      'openid',
      'profile',
      'email',
      'offline_access',
      clientScope,
      'okta.myAccount.email.read',
      'okta.myAccount.email.manage'
    ]
  })
}

const restoreOriginalUri = () => {
  window.location.href = '/'
}

const AUTH_TABS = [
  {
    autobotID: 'AUTH_TAB_SIGN_IN',
    name: 'SIGN IN',
    tabText: 'Sign In',
    id: 'authenticate',
    label: 'Sign In to your account',
    query: { flow: 'authenticate' }
  },
  {
    autobotID: 'AUTH_TAB_CREATE_ACCOUNT',
    name: 'CREATE ACCOUNT',
    tabText: 'Create Account',
    id: 'register',
    label: 'Create an account',
    query: { flow: 'register' }
  }
] as const

function logSignInSuccess() {
  analytics.log({
    message: `Standalone - login complete event received`
  })
  const signInMethod = sessionStorage.getItem('standalone_signin_with')
  if (signInMethod) {
    sessionStorage.removeItem('standalone_signin_with')
    fireLoginEvent(signInMethod)
  }
}

function Okta({
  redirectUrl,
  initialTransaction = null,
  clientId,
  issuer,
  callbackUrl,
  clientScope
}: InitialTransaction) {
  const [transaction, setTransaction] = useState<IdxTransaction | null>(
    initialTransaction
  )

  const [errorMessage, setErrorMessage] = useState<string>('')
  const [showSpinner, setShowSpinner] = useState(false)
  const [slideInRight, setSlideInRight] = useState(true)
  const [shouldVerifyEmail, setShouldVerifyEmail] = useState(false)
  const [isEmailAlreadyVerified, setIsEmailAlreadyVerified] = useState(false)
  const [emailID, setEmailID] = useState('')
  const [accessToken, setAccessToken] = useState('')
  const [componentToShow, setComponentToShow] = useState<ReactNode>()
  const [buttonsDisabled, setButtonsDisabled] = useState(false)

  const verifyUserOnSignIn = useSeti('SIGNIN_VERIFY', false) === 'VARIANT'

  const oktaClient = useMemo(
    () => oktaAuth({ clientScope, clientId, issuer, callbackUrl }),
    [clientId, clientScope, issuer, callbackUrl]
  )

  const { asPath, push, query, pathname, replace } = useRouter()

  const logAnalytics = (message: string) => {
    analytics.log({
      message
    })
  }

  const [activeFlow, setActiveFlow] = useState(
    query.flow ? (query.flow as FlowIdentifier) : ('register' as FlowIdentifier)
  )
  useEffect(() => {
    const { tokens, status, nextStep } = transaction || {
      tokens: {},
      status: ''
    }

    const isEmailVerified = nextStep && isEmailVerifried(nextStep)

    if (isEmailVerified) {
      setIsEmailAlreadyVerified(true)
    }

    const proceedOktaFlow = async () => {
      if (!localStorage.getItem('standalone_redirect')) {
        localStorage.setItem('standalone_redirect', redirectUrl)
      }
      try {
        await refreshToken(oktaClient)
        const newTransaction = await oktaClient.idx.proceed()
        setTransaction(newTransaction)
      } catch (error) {
        const catchErrorMessage =
          error instanceof Error
            ? error.message
            : error?.toString() ?? 'Unknown error'
        analytics.logError({
          message: 'Standalone - proceedOktaFlow failed',
          errorMessage: catchErrorMessage,
          redirectUrl
        })
      }
    }

    const checkEmailStatus = async () => {
      const shouldSkipEmailVerifaction = sessionStorage.getItem(
        'shouldSkipEmailVerifaction'
      )
      if (tokens?.accessToken?.accessToken) {
        if (
          activeFlow === 'authenticate' &&
          shouldSkipEmailVerifaction !== 'true' &&
          !isEmailAlreadyVerified
        ) {
          pclnExperimentation.fireImpression('SIGNIN_VERIFY')
          if (verifyUserOnSignIn) {
            const response = await checkUserStatus(
              tokens.accessToken.accessToken
            )
            const { ok, body } = { ...response }

            if (
              ok &&
              body &&
              Array.isArray(body) &&
              body[0].status === 'UNVERIFIED'
            ) {
              setShouldVerifyEmail(true)
              setAccessToken(tokens.accessToken.accessToken)
              setEmailID(body[0].id)
            } else {
              oktaClient.tokenManager.setTokens(tokens)
            }
          } else {
            oktaClient.tokenManager.setTokens(tokens)
          }
        } else {
          sessionStorage.removeItem('shouldSkipEmailVerifaction')
          oktaClient.tokenManager.setTokens(tokens)
        }
      }
    }

    const startOkta = async () => {
      if (status === IdxStatus.SUCCESS && tokens === undefined) {
        await proceedOktaFlow()
      } else if (status === IdxStatus.SUCCESS) {
        logSignInSuccess()
        sessionStorage.removeItem('checkoutURL')
        await checkEmailStatus()
      } else if (status === IdxStatus.TERMINAL) {
        logAnalytics(`Standalone - switched to Terminal page`)
        sessionStorage.setItem(
          'okta_terminal_message',
          JSON.stringify(transaction?.messages, null, 4)
        )
        void push('/home/join/terminal')
      } else if (status === IdxStatus.FAILURE) {
        logAnalytics(`Standalone - switched to Error page`)
        void push('/home/join/error')
      } else if (status === IdxStatus.CANCELED) {
        logAnalytics(`Standalone - switched to Canceled page`)
        void push('/home/join/canceled')
      }
    }

    void startOkta()
  }, [
    transaction,
    oktaClient,
    activeFlow,
    push,
    verifyUserOnSignIn,
    isEmailAlreadyVerified,
    redirectUrl
  ])

  useEffect(() => {
    firePageViewEvent(activeFlow)
  }, [activeFlow])

  useEffect(() => {
    const resumeTransaction = async () => {
      try {
        const newTransaction: IdxTransaction = await oktaClient.idx.proceed()
        setTransaction(newTransaction)
      } catch (error) {
        const catchErrorMessage =
          error instanceof Error
            ? error.message
            : error?.toString() ?? 'Unknown error'
        analytics.logError({
          message: `Standalone - resume transaction failed: ${catchErrorMessage}`
        })
      }
    }

    if (
      !asPath?.includes('error') &&
      !isRedirectUri(window.location.href, oktaClient) &&
      oktaClient.idx.canProceed()
    ) {
      void resumeTransaction()
    }
  }, [asPath, oktaClient])

  useEffect(() => {
    let component = (
      <FlowPage
        redirectUrl={redirectUrl}
        transaction={transaction}
        setTransaction={setTransaction}
        setSlideInRight={setSlideInRight}
        slideInRight={slideInRight}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        showSpinner={showSpinner}
        setShowSpinner={setShowSpinner}
        flowParameter={activeFlow}
        buttonsDisabled={buttonsDisabled}
        setButtonsDisabled={setButtonsDisabled}
      />
    )
    if (asPath?.includes('terminal')) {
      component = <TerminalPage />
    }

    if (asPath?.includes('error')) {
      component = <ErrorPage />
    }

    if (asPath?.includes('canceled')) {
      component = <CanceledPage />
    }

    if (asPath?.includes('login/callback')) {
      component = (
        <LoginCallback
          transaction={transaction}
          setTransaction={setTransaction}
        />
      )
    }
    if (shouldVerifyEmail) {
      component = (
        <VerifyEmail
          accessToken={accessToken}
          emailID={emailID}
          oktaClient={oktaClient}
          buttonsDisabled={buttonsDisabled}
          setButtonsDisabled={setButtonsDisabled}
        />
      )
    }

    setComponentToShow(component)
  }, [
    redirectUrl,
    transaction,
    errorMessage,
    showSpinner,
    activeFlow,
    buttonsDisabled,
    asPath,
    slideInRight,
    shouldVerifyEmail,
    accessToken,
    emailID,
    oktaClient
  ])

  const handleOnClick = (tab: AuthTabType) => {
    setErrorMessage('')
    if (activeFlow !== tab.id) {
      setShowSpinner(true)
    }

    setActiveFlow(tab.id)
    void push(
      {
        pathname,
        query: {
          ...query,
          flow: tab.query.flow
        }
      },
      undefined,
      { shallow: true }
    )
  }
  const isBackButtonHidden =
    transaction?.nextStep?.name === 'enroll-authenticator'

  return (
    <Security oktaAuth={oktaClient} restoreOriginalUri={restoreOriginalUri}>
      {buttonsDisabled && <Scrim />}
      <Container size="sm" p={4}>
        <Flex
          flexDirection="column"
          justifyContent="space-between"
          style={{ minHeight: '90vh' }}
        >
          <Box px={[0, null, 5]}>
            {!isBackButtonHidden && (
              <BackButton onNavigateBack={replace} redirectUrl={redirectUrl} />
            )}
            <Flex
              alignItems="center"
              justifyContent="center"
              mt={[3, null, 4]}
              mb={24}
              role="tablist"
            >
              {AUTH_TABS.map(tab => {
                const isActive =
                  activeFlow !== undefined
                    ? tab.id === activeFlow
                    : tab.id === 'register'

                return (
                  <Box width={1 / 2} mx={1} key={tab.id}>
                    <ButtonChip
                      disabled={
                        !transaction?.nextStep || showSpinner || buttonsDisabled
                      }
                      id={`tab-${tab.id}`}
                      data-testid={tab.autobotID}
                      aria-selected={isActive}
                      aria-label={tab.label}
                      size="md"
                      role="tab"
                      width="100%"
                      expanded={isActive}
                      onClick={() => {
                        void handleOnClick(tab)
                      }}
                    >
                      <Box px={3} py={1}>
                        <Text textAlign="center" regular fontSize={1}>
                          {tab.tabText}
                        </Text>
                      </Box>
                    </ButtonChip>
                  </Box>
                )
              })}
            </Flex>
            {componentToShow}
            <Box mb="48px" />
          </Box>
          <Box px={[0, null, 5]}>
            <Text textStyle="disclaimer">
              By signing in or creating an account, you agree with our{' '}
              <Link href="/static-pages/terms_en.html">
                Terms & Conditions{' '}
              </Link>{' '}
              and{' '}
              <Link href="/static-pages/privacy-policy.html">
                Privacy Statement
              </Link>
              .
            </Text>
          </Box>
        </Flex>
      </Container>
    </Security>
  )
}

export default Okta
